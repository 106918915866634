import { PageProps } from 'gatsby';
import { FC } from 'react';
import tw, { styled } from 'twin.macro';
import {
  BlueButton,
  CallOut,
  ElWithColor,
  FSOverlay,
  H1,
  H2,
  H3,
  LI,
  LoadIndicator,
  UL,
  Uppercase,
  withColors,
} from '~/components';
import { Frontmatter } from '~/config';

// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Library: Twin Playground',
  orderInLib: 0,
  screenShoot: false,
};

// Shows 3 flavors of Twin: https://github.com/ben-rogerson/twin.macro

const HelloBtn = tw.button`
  bg-brand-pink hover:bg-opacity-50 text-white font-bold font-secondary  
  py-2 px-4 rounded`;

const StyledBtn = styled.button<{ purple?: boolean }>`
  margin-top: 4px;
  font-weight: bold;
  padding: 8px;
  ${({ purple }) => purple && tw`bg-indigo-600 text-white font-secondary`}
`;

const Gray = tw.span`text-brand-gray`;

export const WithColorTester: FC<ElWithColor<HTMLDivElement>> = (props) => (
  <div css={[withColors(props), tw`p-3`]}>{props.children}</div>
);

const Page: FC<PageProps> = () => {
  return (
    <div>
      <FSOverlay hcenter>
        <div tw="p-6 max-w-xl">
          <div tw="bg-brand-pink text-white font-bold mb-1 p-2">
            Inline tw attribute
          </div>
          <HelloBtn tw="mr-2">Hello, y'all!</HelloBtn>
          <StyledBtn purple>A styled button</StyledBtn>
          <BlueButton arrowStyle="down" customCss={tw`ml-2`}>
            Hi, download me
          </BlueButton>
          <H1 giant>Hello H1 giant!</H1>
          <H1>Hello H1!</H1>
          <H2>Hello H2!</H2>
          <H2 riskyPink>Hello H2 riskyPink!</H2>
          <H2
            white
            bgColor="yellow"
            borderColor="gray"
            borderWidth={2}
            borderStyle="dashed"
            borderRadius={5}
          >
            Hello colorful H2!
          </H2>
          <H3>Hello H3!</H3>
          <H3 riskyGray normalCase>
            Hello H3 normal-case!
          </H3>
          <WithColorTester white bgColor="cyan" borderRadius={100}>
            For testing color functions
          </WithColorTester>
          <WithColorTester
            color="riskyPink"
            borderColor="gray"
            borderWidth={2}
            borderRadius={5}
          >
            For testing color functions
          </WithColorTester>
          <p>
            Hello <Uppercase cyan>Uppercase cyan</Uppercase> paragraph!
            Farm-to-table affogato locavore, intelligentsia paleo lyft pitchfork
            fingerstache meh cray echo park put a bird on it truffaut art party.
            XOXO tilde farm-to-table chicharrones aesthetic subway tile
            humblebrag, deep v pinterest narwhal biodiesel bushwick. Activated
            charcoal polaroid freegan yuccie. Yr synth edison bulb tbh ugh raw
            denim 3 wolf moon, mixtape copper mug master cleanse godard fanny
            pack coloring book XOXO pabst. Master cleanse chambray franzen,
            mumblecore craft beer poutine swag art party hashtag.
          </p>
          <CallOut
            bgColor="pastelBlue"
            barColor="cyan"
            ctaText="This text doesn't use uppercase"
          >
            <BlueButton>Text</BlueButton>
          </CallOut>
          <CallOut
            bgColor="pastelBlue"
            barColor="cyan"
            ctaText="caps{This text does use uppercase!}"
          >
            <BlueButton>Text</BlueButton>
          </CallOut>
          <div>
            Hello div with some{' '}
            <small>
              smol <strong>strong</strong> text
            </small>{' '}
            and also a{' '}
            <a href="/">
              link <em>wow</em>
            </a>{' '}
            and don't forget <Gray>brand gray</Gray>!
          </div>

          <H3 normalCase>Here's a list:</H3>
          <UL>
            <li>List</li>
            <li>List</li>
          </UL>

          <H3 normalCase>Here's a list with pink bullets and gray text:</H3>
          <UL riskyPink>
            <LI gray>
              List with pink bullets List with pink bullets List with pink
              bullets List with pink bullets List with pink bullets List with
              pink bullets List with pink bullets{' '}
            </LI>
            <li>List with pink bullets</li>
          </UL>
          <H3 normalCase>Here's a list with yellow bullets:</H3>

          <UL yellow>
            <li>List with yellow bullets</li>
            <li>List with yellow bullets</li>
          </UL>

          <H3 normalCase>Here's a list with cyan dashes:</H3>
          <UL cyan dash>
            <li>List with cyan dashes</li>
            <li>List with cyan dashes</li>
          </UL>
          <LoadIndicator />
        </div>
      </FSOverlay>
    </div>
  );
};

export default Page;
